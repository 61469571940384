<template>
  <div class="pay-contianer" v-wait="loading">
    <pageBread
      :img="() => require('../../assets/image/zhuye.png')"
      text="普货付款"
    ></pageBread>
    <div class="padding-10" v-if="!loading">
      <p>
        您的订单号为：<span style="color: red"
          >{{ payDetailInfo.id.split("(")[0] }} &nbsp;</span
        >的包裹已打包完成
      </p>
      <template v-for="item in payDetailInfo.packInfo.split('\n')">
        <p :key="item" style="color: red">{{ item }}</p>
      </template>
      <p>
        寄送国家为：<span style="color: red; padding-right: 8px">{{
          payDetailInfo.nation
        }}</span>
      </p>
      <p>
        收货地址为：<span style="color: red">{{ payDetailInfo.address }}</span>
      </p>
      <p>确认无误后请按照以下步骤完成费用支付，我们会尽快安排为您发货！</p>
    </div>
    <div class="step padding-10">
      <p class="step-title">第一步：渠道选择</p>
      <p>我们已为您整理推荐渠道，请进行渠道选择</p>
      <select v-model="payDetailInfo.lineCode">
        <option value="" label="请选择线路">请选择线路</option>
        <option
          :value="item.id"
          v-for="item in lines"
          :key="item.id"
          :label="item.lineName"
        >
          {{ item.lineName }}
        </option>
      </select>
      <p>
        实重为<span style="color: red; padding: 0px 5px">{{
          payDetailInfo.weight
        }}</span
        >kg
      </p>
      <p>
        体积重为<span style="color: red; padding: 0px 5px">{{
          payDetailInfo.volumn
        }}</span>
      </p>
      <p>
        该渠道的计费重量为<span style="color: red; padding: 0px 5px">{{
          payDetailInfo.realWeight
        }}</span>
      </p>
      <p>
        该渠道的总运费为<span style="color: red; padding: 0px 5px">{{
          payDetailInfo.cost
        }}</span
        >元
      </p>
    </div>
    <div class="step padding-10">
      <p class="step-title">第二步：请选择支付方式并完成支付</p>
      <p>选择优惠券</p>
      <select v-model="yhqValue" @change="yhqChange">
        <option value="">请选择优惠券</option>
        <option
          v-for="(item, index) in yhqList"
          :key="index"
          :label="item.name"
          :value="item.id"
        >
          {{ item.name }}
        </option>
      </select>
      <p>支付方式</p>
      <p>
        <input
          type="radio"
          name="payWay"
          v-model="payWay"
          value="1"
        />账户余额（<span>{{ payDetailInfo.balance }}</span
        >）<span style="font-size: 12px; color: red"
          >余额小于支付金额时，将使用微信支付剩余金额</span
        >
      </p>
      <p><input type="radio" name="payWay" v-model="payWay" value="2" />微信</p>
    </div>
    <div
      style="text-align: center; color: red; font-size: 20px; margin: 30px 0px"
    >
      {{ afterValue }}元
      <span style="color: black" v-if="payDetailInfo.cost !== ''"
        >(原价：{{ payDetailInfo.cost }} 元)</span
      >
    </div>
    <div style="text-align: center; margin: 10px 0px">
      <button class="nq-button" @click="confirmPay" v-loading="payLoading">
        确定支付
      </button>
    </div>
  </div>
</template>
<script>
import { shippedInfo } from '../../utils/coupon.js'
export default {
  name: 'toPay',
  data() {
    return {
      errorInfo: '',
      payInfo: '',
      payLoading: false,
      loading: false,
      orderId: '',
      lines: [],
      payDetailInfo: {},
      yhqList: [],
      yhqValue: '',
      afterValue: '',
      payWay: '1'
    }
  },
  created() {
    this.getOrderInfo()
  },
  mounted() {},
  methods: {
    getOrderInfo() {
      // 获取线路
      this.$reqGet(shippedInfo.linesList + '?orderNum=' + this.$route.params.orderNo).then((res) => {
        if (res.data.code === 200) {
          this.lines = res.data.data
        }
      })
      // 获取支付详情
      this.loading = true
      this.$reqGet(
        shippedInfo.payDetailInfo + '?id=' + this.$route.params.id
      ).then((res) => {
        this.loading = false
        if (res.data.code === 200) {
          this.payDetailInfo = res.data.data
          this.afterValue = res.data.data.money
          // 获取优惠券
          this.$reqGet(
            shippedInfo.youhuiquan + '?money=' + res.data.data.money
          ).then((res) => {
            if (res.data.code === 200) {
              this.yhqList = res.data.data
            }
          })
        }
      })
    },
    // 获取优惠券后的值
    yhqChange(value) {
      if (this.yhqValue !== '') {
        this.$reqPost(shippedInfo.yhPrice, {
          couponId: this.yhqValue, // 优惠券Id
          money: this.payDetailInfo.money // 运费
        }).then((res) => {
          if (res.data.code === 200) {
            this.afterValue = res.data.data.money
          }
        })
      } else {
        this.afterValue = this.payDetailInfo.money
      }
    },
    wxPay(data) {
      this.payInfo = data
      if (typeof WeixinJSBridge === 'undefined') {
        if (document.addEventListener) {
          document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false)
        } else if (document.attachEvent) {
          document.attachEvent('WeixinJSBridgeReady', onBridgeReady)
          document.attachEvent('onWeixinJSBridgeReady', onBridgeReady)
        }
      } else {
        onBridgeReady()
      }
      const _me = this
      function onBridgeReady() {
        WeixinJSBridge.invoke(
          // eslint-disable-line no-undef
          'getBrandWCPayRequest',
          {
            appId: data.appId,
            timeStamp: data.timeStamp,
            nonceStr: data.nonceStr,
            package: data.package,
            signType: data.signType,
            paySign: data.paySign
          },
          function(res) {
            _me.errorInfo = res
            if (res.err_msg === 'get_brand_wcpay_request:ok') {
              // 使用以上方式判断前端返回,微信团队郑重提示：res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
              _me.$router.replace('/order-management/2')
            }
          }
        )
      }
    },
    confirmPay() {
      if (this.payDetailInfo.lineCode === '') {
        this.$toast.success('请选择线路')
        return
      }
      if(this.afterValue === ""){
        this.$toast.success('付款出现问题，请联系客服')
        return
      }
      this.payLoading = true
      // 微信支付
      if (this.payWay === '2') {
        this.$reqPost(shippedInfo.weixinPay, {
          productId: this.payDetailInfo.id.split('(')[0],
          money: this.afterValue,
          couponId: this.yhqValue
        }).then((res) => {
          this.payLoading = false
          if (res.data.code === 200) {
            this.wxPay(res.data.data)
          } else {
            this.$toast.error(res.data.message)
          }
        })
      } else {
        const yue = Number(this.payDetailInfo.balance)
        const needMoney = Number(this.afterValue)
        // 余额支付，当余额充足的时候
        if (yue >= needMoney) {
          this.$reqGet(shippedInfo.toPayMoney, {
            ordNum: this.payDetailInfo.id.split('(')[0],
            money: needMoney + '',
            couponId: this.yhqValue
          }).then((res) => {
            this.payLoading = false
            if (res.data.code === 200) {
              this.$toast.success('支付成功')
              setTimeout(() => {
                this.$router.replace('/order-management/2')
              }, 300)
            } else {
              this.$toast.error(res.data.message)
            }
          })
        } else {
          // 余额支付，当余额不够的时候
          this.$reqPost(shippedInfo.weixinPay, {
            productId: this.payDetailInfo.id.split('(')[0],
            money: needMoney, // 需要微信支付的钱数
            accountMoney: yue,
            couponId: this.yhqValue
          }).then((res) => {
            this.payLoading = false
            if (res.data.code === 200) {
              this.wxPay(res.data.data)
            } else {
              this.$toast.error(res.data.message)
            }
          })
        }
      }
    }
  }
}
</script>
<style scoped>
.pay-contianer {
  background: #fff;
  height: 100%;
  overflow: auto;
  font-size: 14px;
  color: #666;
}
.step-title {
  background: #ededed;
  padding: 5px;
  margin: 10px 0px;
}
select {
  width: 100%;
  height: 35px;
  margin: 10px 0px;
}
input[type="checkbox"] {
  margin-right: 10px;
}
p {
  padding: 2px 0px;
}
</style>